module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-88909332-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Scott Rankin","short_name":"scottrank.in","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0cb8e8722ebdca5255cacb8c2543ecce"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
